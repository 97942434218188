// Relativ path to images folder (used in the image-url() function)
$imagePath: "../images/" !default;

// +++++ Font settings
// Font size of main content (in px)
$baseFontSize: 18px;

// Line height of main content (in px)
$baseLineHeight: 29px;

// Headline font sizes (in px)
$h1Size: 40px;
$h2Size: 40px;
$h3Size: 35px;
$h4Size: 30px;
$h5Size: 25px;
$h6Size: 20px;

// Font stacks: $mainFont for simple content, $displayFont for headlines etc., $iconFont for icons
$mainFont:    "Open Sans", sans-serif;
$displayFont: "Open Sans", sans-serif;
$iconFont:   "icomoon";

// +++++ Design & Layout
// Colours for your design.
$primary:   #3caad3;
$secondary: #fdc42a;
$light:     #fff;
$medium:    #c1c1c1;
$dark:      #474747;
$border:    #43ac32;
$alert:     #44328a;

$yellow:    #ffd200;
$red:       #c10000;
$blue:      #55abdd;
$green:     #43ac32;

// Horizontal padding left and right of grid columns
$baseGap: 18px;

// Grid row max-width
$rowMaxWidth: 1400px;

// Grid columns
$columnCount: 12;

// Standard border for your design
$baseBorder: 1px solid $border;

// Standart outline styles for focus states
$outline: 1px dotted $alert;

// Assume a different base font size for breakpoint em calculation (default: 16px)
$bpContext: 16px;

// +++++ Miscellaneous
// text-indent value when using hideText(): "right" = positive value, "left" = negative value
$hideTextDirection: "right";

// $iconMap: (
//     times: "×",
//     bars: "≡",
//     minus: "-",
//     angle-right: "❯",
//     plus: "+",
//     angle-up: "↑",
//     exclamation: "!"
// );

$iconMap: (
    times: "×",
    bars: "≡",
    minus: "-",
    plus: "+",

    angle: "\f105",   
    exclamation: "\e902",
    arrow: "\e903",

    phone: "\e90d",
    mail: "\f003",
    time: "\e905",
    hotline: "\e904",

    desinfect: "\e900",
    sink: "\e909",
    toilet: "\e90a",
    cabinet: "\e901",

    house: "\e906"
);


/*  Grid Map 
    Define your breakpoints and grid classes below.
    Only generate the gridclasses you really need to reduce filesize.
    example:
    tiny: ( <-- "breakpoint name"
        width:  em(480px, $bpContext), <-- "breakpoint width"
        cols: (5,6,12), <-- "columns" result generates .tiny-5, .tiny-6 and .tiny-12
        prefix: (2,4), <-- "prefix" result generates .tiny-prefix-2 and .tiny-prefix-4
        suffix: (6,8), <-- "suffix" result generates .tiny-suffix-6 and .tiny-suffix-8
    )
*/
$gridMap: (
    tiny: (
        width:  em(480px, $bpContext),
        cols:(10),
        prefix: (1)
    ),
    small: (
        width:  em(640px, $bpContext),
        cols: (6,8),
        prefix:(2,3)
    ),
    medium: (
        width:  em(800px, $bpContext),
        cols: (6,8,4),
        prefix: (3,0)
    ),
    large: (
        width:  em(992px, $bpContext),
        cols: (3,8,4,6),
        prefix: (4)
    ),
    giant: (
        width:  em(1200px, $bpContext),
        cols: (3)
    ),
    huge: (
        width:  em(1364px, $bpContext)
    ),
    full: (
        width:  em(1520px, $bpContext)
    )
);