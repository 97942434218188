// PAGEWRAP 
//////////////////////////////

#pageWrap {
	margin-top: rem(50px);
	margin-bottom: 3.5rem;
	
	@include breakpoint($break) {
		margin-top: rem(120px);
		margin-bottom: 0;
	 }

	@include breakpoint(giant) {
		margin-top: rem(170px);
	 }
			
	
}

// TOPBAR
//////////////////////////////

.topbar {
	width: 100%;
	background: $light;
	
	@include breakpoint($break) {
		position: fixed;
		z-index: 3;
		top: 0;
	 }
	
	
	#pageNavi {
		display: flex;
		align-items: center;
		justify-content: space-between;
	
		.branding {	
			width: 25%;
			display: none;
			max-width: rem(180px);

			@include breakpoint($break) {
				display: block;
				margin: 0  rem($baseGap) 0 2rem;
			 }

			 @include breakpoint(giant) {
			 	max-width: rem(260px);
			 	width: 35%;
			 }
			 
			
		}

		nav {
			width: 100%;
			@include breakpoint($break) {
				width: 70%;
			 }			 
			
		}
	}
}

.dog {
	max-width: 30rem;
	margin-bottom: 2.5rem;

	@include breakpoint(large) {
		max-width: none;
	 }
	
	@include breakpoint(huge) {
		margin-left: -2.5rem;
	 }
	
}


// NAVI-CHANGE
/////////////////////////////

.navi-change {
	@include breakpoint($break) {

	.topbar {
		-webkit-box-shadow: 0px 2px 14px -2px rgba(0,0,0,0.1);
		-moz-box-shadow: 0px 2px 14px -2px rgba(0,0,0,0.1);
		box-shadow: 0px 2px 14px -2px rgba(0,0,0,0.1);
		#pageNavi {
		nav {
			li {
				a,span {
					padding: 2.5rem rem($baseGap) !important;
				}

				&.hasSub {
					.sub {
						li {
							a, span {
								padding: 1rem rem($baseGap) !important;
							}
						}
					}
				}
			}
		}

		.branding {
			max-width: rem(180px)
		}
	}
	 }

}

}


// HEADER
//////////////////////////////

#pageHeader {
	width: 100%;
	position: relative;
	overflow: hidden;
	background: $light;
	height: 15rem;

	@include breakpoint(small) {
		height: 20rem;	
	 }
	
	
	@include breakpoint($break) {
		display: flex;
		justify-content: space-between;
		height: 27rem;
	 }
	

	@include breakpoint(giant) {
		height: 42rem;
	 }
	

	// .video-background {
	// 	  background: #000;
	// 	  position: relative;
	// 	  top: 0; right: 0; bottom: 0; left: 0;
	// 	  z-index: 1;
	// 	  height: 20rem;
	// 	}
	// .video-foreground,
	// .video-background iframe {
	// 	  position: absolute;
	// 	  top: 0;
	// 	  left: 0;
	// 	  width: 100%;
	// 	  height: 100%;
	// 	  pointer-events: none;
	// 	}

	.right {
		width: 100%;
		right:0;
		height: 100%;
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center center;

		@include breakpoint(medium) {
			width: 50%;
		 }

		@include breakpoint($break) {
			margin-left: rem($baseGap);
		 }
		

		@include breakpoint(giant) {
			width: 65%;
		 }

		img {
			width: 3.5rem;
			height: auto;
			display: block;
			float: left;
			bottom: 0;
			position: absolute;
			margin-bottom: rem($baseGap);
			left: 1rem;

			&.seal {
				z-index: 2;
			}

			&.first-seal {
				left: 5rem;

				@include breakpoint($break) {
					left: auto;
					right: 9rem;
				 }
				

				@include breakpoint(large) {
					right: 11rem;
				 }
				
			}

			@include breakpoint($break) {
				right: 5rem;
				left: auto;
			 }
			

			@include breakpoint(large) {
				width: 5rem;
			 }
			
		}

	.swiper-container {
		height: 100%;

		.swiper-wrapper {
			.swiper-slide {
				width: 100% !important;
				background-repeat: no-repeat;
				background-size: cover;
				background-position: center center;
				&.slider01 {
					background-image: url('/images/slider/slider01-tiny.jpg');

					@include breakpoint(medium) {
						background-image: url('/images/slider/slider01-medium.jpg');
					 }

					@include breakpoint(large) {
						background-image: url('/images/slider/slider01.jpg');
					}
				}

				&.slider02 {
					background-image: url('/images/slider/slider02-tiny.jpg');

					@include breakpoint(medium) {
						background-image: url('/images/slider/slider02-medium.jpg');
					 }

					@include breakpoint(large) {
						background-image: url('/images/slider/slider02.jpg');
					}
				}

				&.slider03 {
					background-image: url('/images/slider/slider03-tiny.jpg');

					@include breakpoint(medium) {
						background-image: url('/images/slider/slider03-medium.jpg');
					 }

					@include breakpoint(large) {
						background-image: url('/images/slider/slider03.jpg');
					}
				}
				
				&.slider04 {
					background-image: url('/images/slider/slider04-tiny.jpg');

					@include breakpoint(medium) {
						background-image: url('/images/slider/slider04-medium.jpg');
					 }

					@include breakpoint(large) {
						background-image: url('/images/slider/slider04.jpg');
					}
				}

				&.slider05 {
					background-image: url('/images/slider/slider05-tiny.jpg');

					@include breakpoint(medium) {
						background-image: url('/images/slider/slider05-medium.jpg');
					 }

					@include breakpoint(large) {
						background-image: url('/images/slider/slider05.jpg');
					}
				}
			}
		}

		.swiper-pagination {
			display: none;

			@include breakpoint($break) {
				display: block;
				margin-left: 1.5rem;
				bottom: 1rem;
			 }
			
			.swiper-pagination-bullet {
				background-color: $light;
				float: left;
				opacity: 0.5;
				
				&.swiper-pagination-bullet-active {
					background-color: $secondary;
					opacity: 1;
				}
			}
		}
	}
		
		
	}

	.header-info {
		left: 0;
		width: 100%;
		height: 100%;
		display: none;

		@include breakpoint(medium) {
			width: 50%;
			display: block;
		 }

		@include breakpoint(large) {
			width: 40;
		 }
		

		@include breakpoint(huge) {
			width: 35%;
		 }
		
		

		.left {
			width: 100%;
			align-items: center;
			align-content: center;
			text-align: center;
			flex-direction: column;
			justify-content: center;
			color: $light;
			padding: 0 2rem;
			display: block;

			@include breakpoint($break) {
				display: flex;
			 }
			

			span {
				font-size: rem(20px);
				font-weight: 400;
				margin-bottom: 0.5rem;
				line-height: 1.5rem;
				display: block;

				@include breakpoint(large) {
					line-height: 2rem;
				 }
				

				strong {
					font-weight: 600;
				}


				@include breakpoint(giant) {
					font-size: rem(28px);
					margin-bottom: 1.5rem;
				 }
				
			}

			&.first {
				height: 10rem;
				background-color: $secondary;

				@include breakpoint($break) {
					height: 65%;
				 }
				

				a {
					background: $light;
					padding: 0.5rem 2rem 0.5rem 4rem;
					border-radius: 100px;
					width: auto;
					text-decoration: none;
					position: relative;
					text-align: left;
					color: $dark;
					min-width: 17rem;

					@include breakpoint(giant) {
						padding: 0.85rem rem($baseGap) 0.85rem 6rem;
						min-width: 21rem;
					 }
					
					

					span {
						display: none;

						@include breakpoint(large) {
							display: block;
							margin-bottom: 0;	
							font-size: rem(20px);
						 }
						
					}

					&:hover {
						background: $primary;

						&:before {
							color: $secondary !important;
						}

					}
					&.phone {
						margin-bottom: rem($baseGap);
						color: $dark;
						margin-top: 1rem;

						@include breakpoint(large) {
							margin-top: 2rem;
						 }
						

						&:before {
							@extend .icon;
							@extend .icon-phone;
							color: $primary;
							font-size: rem(19px);
							left: 2rem;
							position: absolute;
							top: 0.75rem;
							

							@include breakpoint(giant) {
								
								left: 2.5rem;
								top:0.9rem;
								font-size: rem(25px);
							 }
							
						}

						&:hover {
							color: $light;
						}
					}

					&.mail {
						color: $dark;
						&:before {
							@extend .icon;
							@extend .icon-mail;
							color: $primary;
							font-size: rem(19px);
							left: 2rem;
							position: absolute;
							top: 0.75rem;

							@include breakpoint(giant) {
								
								left: 2.5rem;
								top:0.9rem;
								font-size: rem(25px);
							 }
							
						}

						&:hover {
							color: $light;
						}
					}
				}
			}

			&.second {
				margin-top: rem($baseGap);
				height: calc(35% - 18px);
				background-color: $primary;

				dl {
					min-width: 20rem;
					dt {
						width: 35%;
						float: left;
						font-weight: 600;

						&:before {
							@extend .icon;
							@extend .icon-time;
							font-weight: 300;
							margin-right: rem($baseGap)
						}
					}

					dd {
						width: 65%;
						float: left;
					}
				}
			}
		}
	}
}

.mobil-info {

	.first {
		background: $primary;
		padding: 2rem 1rem;
		color: $light;

		span {
			text-align: center;
			display: block;

			strong {
				font-weight: 600;
			}
		}

		.btn-box {
			margin-top: 1rem;
			display: flex;
			justify-content: space-around;
			flex-direction: column;
			a {
				background: $light;
				color: $primary;
				text-decoration: none;
				padding: 0.5rem 1.5rem 0.5rem 3rem;
				border-radius: 100px;
				min-width: 10rem;

				&:hover {
					background: $secondary;
				}
			}
		}
	}
	@include breakpoint($break) {
		display: none;
	 }
	
}

// MAIN CONTENT
//////////////////////////////

.content-space {
	margin: 3rem auto;

	@include breakpoint(medium) {
		margin: 4rem auto;
	 }

//	@include breakpoint(giant) {
//		margin: 8rem auto;
//	 }	
}

.responsive-video iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.responsive-video {
	position: relative;
	padding-bottom: 56.25%; /* Default for 1600x900 videos 16:9 ratio*/
	padding-top: 0px;
	height: 0;
	overflow: hidden;
}

.farben-system {
	p {
		text-align: center;
		width: 100%;
		margin-bottom: 2.5rem;

		@include breakpoint(large) {
			font-size: rem(22px);
		 }
		
	}
	.grey {
		background-color: lighten($medium, 20%);
		border-radius: rem(100px);
		display: flex;
		align-content: center;
		align-items: center;
		margin-bottom: rem(50px);
		text-align: center;

		&.nomarg {
			@include breakpoint(small) {
				margin-bottom: 0;
			 }
			
		}
	}
	i {
		color: $light;
		width: rem(70px);
		height: rem(70px);
		border-radius: rem(100px);
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: rem(40px);
		align-self: flex-start;

		&.yellow {
			background: $yellow;
		}

		&.red {
			background: $red;
		}

		&.blue {
			background: $blue;
		}

		&.green {
			background: $green;
		}

		@include breakpoint(medium) {
			width: rem(90px);
			height: rem(90px);
			font-size: rem(50px);	
		 }
		

		@include breakpoint(giant) {
			width: rem(130px);
			height: rem(130px);
			font-size: rem(70px);
		 }
		
	}

	span {
		margin: 0 auto;
		font-size: rem(17px);
		line-height: 1.3rem;
		strong {
			display: block;
			font-weight: 500;
		}

		@include breakpoint(giant) {
			font-size: rem(22px);
			line-height: 1.5rem;
		 }
		
	}
}

.leistung {
	text-decoration: none;
	display: flex;
	margin-bottom: 2rem;



	@include breakpoint(medium) {
		margin-bottom: 0;
		display: block;
	 }
	

	img {
		width: 4.5rem;
		height: 4.5rem;
		border-radius: rem(500px);
		margin-right: 1rem;

		&:hover {
			transform: scale(1.05);
			transition: 0.3s ease-in-out;
			opacity: 0.8;
		}

		@include breakpoint(tiny) {
			margin-right: 1.5rem;
		 }
		

		@include breakpoint(medium) {
			width: 100%;
			height: auto;
			opacity: 1;
		 }
		
	}

	span {
		width: auto;	
		text-align: center;
		color: $dark;
		font-size: rem(17px);
		align-self: center;

		&:hover {
			transform: scale(1.05);
			transition: 0.3s ease-in-out;
		}

		@include breakpoint(medium) {
			font-size: $baseFontSize;
			width: 100%;
			display: block;
			margin: 2rem 0 4rem;

			&.nomarg {
				margin-bottom: 0;
			}
		 }

		 @include breakpoint(large) {
		 	font-size: rem(21px);
		  }
		 
		
	}
}

main {
	display: block;

	p,
	ol,
	ul,
	dl,
	table {
		@extend .marginBottom;
	}

	ul:not(.unstyledList),
	dl:not(.unstyledList) {
		@extend .styledList;
	}

	.googleMaps {
		@extend .videoContainer;

		@include breakpoint(medium) {
			height: rem(400px);
		}
	}
}

// FOOTER
//////////////////////////////
footer{
	margin-bottom: 8rem;
}

#pageFooter {
	margin: 3rem auto 8rem;

	@include breakpoint(medium) {
		margin: 0rem auto 0;
	 }

	@include breakpoint(giant) {
		margin: 0rem auto 0;
	 }	
	 body.index &{
	 	margin: 3rem auto 8rem;

		@include breakpoint(medium) {
			margin: 3rem auto 0;
		 }

		@include breakpoint(giant) {
			margin: 4rem auto 0;
		 }
	 }
	.gmaps {
		width: 100%;
		height: 15rem;

		@include breakpoint(medium) {
			height: 20rem;
		 }

		@include breakpoint(giant) {
			height: 25rem;
		 }
		
		

		iframe {
			height: 100%;
			width: 100%;
		}
	}

	.navi {
		display: none;
		justify-content: flex-end;
		position: relative;
		height: 100%;

		@include breakpoint($break) {
			display: flex;
		 }
		

		li {
			height: auto;

			a {
				text-decoration: none;
				color: $dark;
				padding: 2.5rem rem($baseGap);
				display: block;

				&:hover, &.active {
					color: $primary;
				}

				&.active {
					font-weight: 500;
				}
			}
		}
	}
}

body:not(.index) {
	#pageFooter {
		// margin: 0;
	}
}

.fassade {
	margin-bottom: 5rem;
}

.abstannd {
	margin-bottom: 2rem!important;
}

.fixedButtons {
	display: none;

	@include breakpoint($break) {
		display: block;
	 }
	
    
            .preview {
                display:none;
            }

               @include breakpoint($break) {
                position: fixed;
                right: rem(-320px);
                z-index: 999999;
                transition: linear .5s all;

                &.hotline {
                	top: 40%;
                }

                &.e-mail {
                	top: calc(40% + 3.5rem);
                }

                &.openings {
                	top: calc((40% + 3.5rem) + 3.5rem);
                }

                 &:hover{
                right:0;
             }

        
        .preview {
            float:left;
            display: block;
            background: $secondary;
            border-radius: rem(100px);

            i {
            	color: $light;
            	padding: 1rem;
            	font-size: rem(22px);            	
            }
           
        }
        .container {
            float:right;
            width:rem(320px);
            background: $light;
            padding: 0.8rem;
            text-align: center;
            border-radius: rem(100px);
            -webkit-box-shadow: 0px 0px 4px -1px rgba(0,0,0,0.3);
			-moz-box-shadow: 0px 0px 4px -1px rgba(0,0,0,0.3);
			box-shadow: 0px 0px 4px -1px rgba(0,0,0,0.3);

            a,span {
            	text-decoration: none;
            	font-size: rem(17px);
            }
        }
        
        }

        @include breakpoint(giant) {
        	&.hotline {
                	top: 40%;
                }

                &.e-mail {
                	top: calc(40% + 4.5rem);
                }

                &.openings {
                	top: calc((40% + 4.5rem) + 4.5rem);
                }

            .preview {
            	i {
            		padding: 1.25rem;
            		font-size: rem(27px);
            	}
            }

            .container {
            	padding: 1.25rem;
            	a, span {
            		font-size: rem($baseFontSize);
            	}
            }
         }
     }


.mobileBtns {
	bottom: 0;
	position: fixed;
	background: $secondary;
	width: 100%;
	display: flex;
	flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-around;
    align-content: stretch;
    align-items: center;
    transition: all 0.5s;
	padding: 0.2em 0;
	height: 3.5em;
	z-index: 1;
	-webkit-box-shadow: 0px 0px 17px 0px rgba(0,0,0,0.2);
	-moz-box-shadow: 0px 0px 17px 0px rgba(0,0,0,0.2);
	box-shadow: 0px 0px 17px 0px rgba(0,0,0,0.2);

	@include breakpoint($break) {
		display: none;
	 }
	

	a, span {
		&.phone-btn {
			&:before {
				@extend .icon;
				@extend .icon-hotline;
				color: $light;
				font-size: rem(28px);
			}
		}

		&.mail-btn {
			&:before {
				@extend .icon;
				@extend .icon-mail;
				color: $light;
				font-size: rem(28px);
				font-weight: 400;
			}
		}
	}

	.display-box {
		display: none;

		dl {
			min-width: 15rem;
			dt {
				font-weight: 500;
				color: $light;
				width: 40%;
				float:left;
				font-size: rem(20px);
			}

			dd {
				color: $light;
				width: 60%;
				float:left;
				font-weight: 400;
				font-size: rem(20px);
			}
		}

		label[for=openingtimes] {
			background: $secondary;
			font-family: $mainFont;
			font-size: rem(18px);
			padding: 0.1rem 0.5rem 0.35rem;
			position: absolute;
			right: 0;
			margin-top: -1.6rem;
			margin-left: -2rem;
			margin-right: 0.4rem;

			&:before {
				display: none;
			}
			&:hover {
				background: $dark;
			}

		}
	}

	input[type=checkbox] {
		position: absolute;
		top: -9999px;
		left: -9999px;
	}

	input[name=openingtimes]:checked ~ .display-box {
		display: flex;
		justify-content: space-around;
		position: absolute;
		bottom: 3.5em;
		background: $primary;
		width: 100%;
		padding: 2em 1em;
	}
	
	label[for=openingtimes] {
		@extend .icon;
		@extend .icon-time;
		color: $light;
		font-size: rem(29px);
		margin-top: rem(-3px);
		font-weight: 300;
	}

}

.vierfarben {
	margin-top: 2rem;
}

body:not(.index) {
	#pageHeader {
		height: 10rem;

		@include breakpoint(small) {
			height: 15rem;
		 }

		@include breakpoint(large) {
			height: 22rem;
		 }
		
		.first-seal, .second-seal {
			display: none;
		}

		
		.header-info {
			display: none;
		}

		.right {
			width: 100%;
			margin-left: 0;

			@include breakpoint(large) {
				background-position: center center;
			 }
			
		}
	}

	.mobil-info {
		display: none;
	}
}

body.index {
	@include breakpoint(giant) {
		.top-left {
	&:before {
		background-image: url('/images/bubbles/top-left.svg');
		background-repeat: no-repeat;
		background-size: contain;
		width: 10rem;
		height: 10rem;
		left: 0;
		margin-top: -7rem;
		position: absolute;
		opacity: 0.7;
		content: '';
	}
}

.middle-right {
	&:after {
		background-image: url('/images/bubbles/middle-right.svg');
		background-repeat: no-repeat;
		background-size: contain;
		width: 9rem;
		height: 10rem;
		right: 0;
		top: 94rem;
		position: absolute;
		opacity: 0.7;
		content: '';
	}
}

.middle-left {
		background-image: url('/images/bubbles/middle-left.svg');
		background-repeat: no-repeat;
		background-size: contain;
		width: 9rem;
		height: 10rem;
		left: 0;
		top: 158rem;
		position: absolute;
		opacity: 0.7;
	}

.bottom-right {
		background-image: url('/images/bubbles/bottom-right.svg');
		background-repeat: no-repeat;
		background-size: contain;
		width: 9rem;
		height: 10rem;
		right: 0;
		top: 205rem;
		position: absolute;
		opacity: 0.7;
	}
	 }
	
}

body.kontaktIndex {
	hr {
		margin: 2rem 0;

		@include breakpoint(medium) {
			margin: 4rem 0;
		 }
		
	}

	dl {
		margin-bottom: 0;

		dt {
			font-weight: 600;
		}
	}
}

body.impressum {
	dl {
		dt {
			font-weight: 600;
		}
	}
}

h4 {
	margin-bottom: 1rem;
}

body.datenschutzerklaerung {
	h4 {
		text-align: center;
		margin-bottom: 1.5rem;
	}

	h5 {
		margin-bottom: 1.5rem;
	}

	@include breakpoint(medium) {
		.column {
			column-count: 2;
			column-gap: 1.5rem;
		}
	 }

	 b {
	 	font-weight: 600;
	 }
	
}


body.agb {
	h3 {
		margin-bottom: 1.5rem;
	}
}

.icon-row {

	.contact-icon {
		display: flex;
	    align-content: center;
	    align-items: center;
	    flex-direction: column;

	    i {
			margin: 0 auto;
			display: block;
			background: $primary;
			color: $light;
			padding: 2rem;
			font-size: rem(40px);
			border-radius: rem(100px);
			margin-bottom: 1rem;
			margin-top: 1.5rem;

		}

		dl {
			background: none;
			dd, dt {
				text-align: center;
				background: none;

				@include breakpoint(large,max) {
					font-size: rem(17px);
				 }
				
			}
		}
	}
}

body.internetExplorer {
	form {
		input#Datenschutz {
			background: white !important;

		}
	}
}

.youtube {
	background-color: #000;
	margin-bottom: 30px;
	position: relative;
	padding-top: 56.25%;
	overflow: hidden;
	cursor: pointer;
}
.youtube img {
	width: 100%;
	top: -16.84%;
	left: 0;
	opacity: 0.7;
}
.youtube .play-button {
	width: 90px;
	height: 60px;
	background-color: #333;
	box-shadow: 0 0 30px rgba( 0,0,0,0.6 );
	z-index: 1;
	opacity: 0.8;
	border-radius: 6px;
}
.youtube .play-button:before {
	content: "";
	border-style: solid;
	border-width: 15px 0 15px 26.0px;
	border-color: transparent transparent transparent #fff;
}
.youtube img,
.youtube .play-button {
	cursor: pointer;
}
.youtube img,
.youtube iframe,
.youtube .play-button,
.youtube .play-button:before {
	position: absolute;
}
.youtube .play-button,
.youtube .play-button:before {
	top: 50%;
	left: 50%;
	transform: translate3d( -50%, -50%, 0 );
}
.youtube iframe {
	height: 100%;
	width: 100%;
	top: 0;
	left: 0;
}

.WidgetBannerContainer{
	@include breakpoint(tiny){
		margin-bottom: 65px;
	}
	@include breakpoint(medium){
		margin-bottom: 0px !important; 
	}
	
}

#pageNavi{
	ul{
		padding-bottom: 70px;
	}
}

.mobileBtns{
	@include breakpoint(tiny){
		margin-bottom: 0px;
	}
	
	.openingtimes{
		width: unset !important;
	}
}

.privacyControlBadge{
	bottom: 140px !important;
	@include breakpoint(tiny){
		bottom: 155px !important;
	}
}

.backToTop{
	bottom: 8.5rem !important;
	@include breakpoint(tiny){
		bottom: 4.5rem !important;
	}
}

.line-clamp {
	width: 100%;
	display: -webkit-box;
	-webkit-line-clamp: 25;
	-webkit-box-orient: vertical;  
	overflow: hidden;
  }

  #mehrLesen {
	  display: none;
	  &:checked {
		  ~.weniger-lesen{
			  &:after{
			  opacity: 1;
			  visibility: visible;
			}
		  }
		  ~.line-clamp{
			-webkit-line-clamp: unset;
		  }
	  }
	 
  }

  .weniger-lesen{
	  position: relative;
	  min-width: 200px;
	  margin: 10px;
	  margin-left: auto;
	  margin-right: auto;

	  &:after {
		  @extend .btn;
		  position:absolute ;
		  top: 0;
		  left: 0;
		  width: 100%;
		  height: 100%;
		content: "Weniger lesen...";
		opacity: 0;
		visibility: hidden;

	}
  }	
	.widgetFlex {
      margin-bottom: 60px;
		@include breakpoint(tiny){
			bottom: 0px;
		}
	}

	.instagram {
		background-image: url(../images/instagram-onecolor.svg);
		background-repeat: no-repeat;
		background-size: contain;
		padding-left: rem(37px);
	}

	.WidgetBannerContainer{
		z-index: 999 !important;
	}
