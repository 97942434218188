// GLOBAL SETTINGS
//////////////////////////////////////////////

$break: medium; // Breakpoint – switch to Desktop version

$desktopHover: true; // Set Hover to reach Submenus for Desktop (true, false)

// NAVIGATION
//////////////////////////////////////////////

$navBg: $light; // Navigation Background
$navColor: $dark; // Navigation Link Color

@mixin navButton {
	// Default Link Styles
	padding: 1em rem($baseGap);
	text-decoration: none;
	color: $navColor;

	@include breakpoint($break) {
		padding: 2.5rem rem($baseGap);
		font-size: rem(16px);
	 }

	@include breakpoint(giant) {
		padding: 4rem rem($baseGap);
		font-size: rem($baseFontSize);
	 }
	
	
}
%buttonHover {
	// Link Hover
	background: $secondary;
	color: $light;
	@include breakpoint($break) {
		color: $secondary;
		background: none;
	 }
	
}
%buttonActive {
	// Link Active
	background: $secondary;
	color: $light;
	@include breakpoint($break) {
		color: $secondary;
		font-weight: 500;
		background: none;
	 }
	
}
%prevIcon {
	// Icon to previus Submenu
	font-family: $iconFont;
	content: map-get($iconMap, angle);
	transform: rotateY(180deg);
}
%nextIcon {
	// Icon to next Submenu
	font-family: $iconFont;
	content: map-get($iconMap, angle);
}
$closeIcon: map-get($iconMap, times); // Icon to close Submenu

// Quickbar
//////////////////////////////////////////////

$barHeight: 50px; // height (in px)
$barPos: top; // position (top, bottom)
$barHide: true; // if bar hides on scroll (true, false)

$barBg: $light; // background-color
$barColor: $secondary; // children colors – texts and burgerbutton

//////////////////////////////////////////////
// NAVIGATION FUNCTIONALITY
//////////////////////////////////////////////

@import "../modules/ruffnav";

// Navigation Styles
////
@include pageNavi {
	background-color: $navBg;
	z-index: 1001;

	nav {
		@include ul {
			&.navi {
				li {
					@include navButtons {
						@include navButton;
					}
					@include hasSub {
						@include sub {
							background: $navBg;

							&:before {
								@extend .h5;
								padding: 1em rem($baseGap);
								background-color: darken($navBg, 6%);
							}
						}
					}
					@include navBack {
					}
				}
			}
		}
	}
}

// Navigation Desktop Styles
////
@include breakpoint($break) {
	@include desktopVersionFunctionality;

	#pageNavi {
		position: relative;

		.header-info {
			display: none;
		}
		nav {
			ul {
				&.navi {
					li {
						&.startseite, &.anfahrt, &.impressum, &.datenschutzerklaerung, &.agb, &.besuchenSieUnsAufInstagram{
							display: none;
						}
						&.hasSub {
							.sub {
								column-count: 2;
								column-gap: 1rem;
								background: $primary;
								left: -100%;
								min-height: 18rem;

								a {
									color: $light;
									padding: 1rem rem($baseGap);

									&:hover, &.active {
										color: $secondary;
									}
								}
							}
						}
						&.nav-back {
						}
					}
				}
			}
		}
	}
}

// Quickbar Styles
////
#quickbar {
	@extend %animatedTransform;
	background-color: $barBg;
	color: $barColor;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: rem($baseGap);
	z-index: 1000;
	-webkit-box-shadow: 0px 2px 14px -2px rgba(0,0,0,0.1);
		-moz-box-shadow: 0px 2px 14px -2px rgba(0,0,0,0.1);
		box-shadow: 0px 2px 14px -2px rgba(0,0,0,0.1);

	.branding {
		max-width: rem(80px)
	}

	@include breakpoint($break) {
		display: none;
	}
}